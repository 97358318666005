<template>
  <div>
    <v-card class="card-shadow border-radius-l mt-6" id="basic">
      <div class="px-6 py-6">
        <h5 class="text-h5 font-weight-bold text-typo">Unternehmen / Firma</h5>
      </div>
      <div class="px-6 pb-6 pt-0">
        <v-row>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Firmenname</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="MusterName"
              v-model="userInformations.username"
              class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Website</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="MusterName"
              v-model="userInformations.website"
              class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Straße + Nr.</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              type="text"
              v-model="userInformations.street"
              placeholder="Musterstraße 1"
              class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >E-Mail
              <span
                class="ml-5"
                :class="
                  userInformations.isVerified ? 'text-success' : 'text-danger'
                "
                >{{
                  userInformations.isVerified ? "bestätigt" : "unbestätigt"
                }}</span
              >
            </label>
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="example@email.com"
              v-model="userInformations.email"
              disabled
              class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
            >
            </v-text-field>
            <v-btn
              block
              v-if="!userInformations.isVerified"
              :elevation="0"
              @click="checkLoginState()"
              class="font-weight-bold text-xs btn-default bg-warning py-5 px-6 mt-6 mb-2 me-2 ms-auto"
              color="warning"
            >
              E-Mail-Adresse bestätigen
            </v-btn>
          </v-col>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" sm="6" md="4">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Postleitzahl</label
              >
              <v-text-field
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                type="number"
                v-model="userInformations.zip"
                placeholder="01234"
                class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Ort</label
              >
              <v-text-field
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder="Wohnort"
                v-model="userInformations.city"
                class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Telefon</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="0123 / 45678910"
              v-model="userInformations.phone"
              class="font-size-input placeholder-lighter border border-radius-md text-light-input mt-2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Handelsregister</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="Handelsregisternummer (sofern vorhanden)"
              v-model="userInformations.register"
              class="font-size-input placeholder-lighter border border-radius-md text-light-input mt-2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Ust.-ID</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="Ust.-ID (sofern vorhanden)"
              v-model="userInformations.vat"
              class="font-size-input placeholder-lighter border border-radius-md text-light-input mt-2"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row>
        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >Sprache</label
          >
          <v-select
            :items="languages"
            v-model="userInformations.language"
            value="Deutsch"
            color="rgba(0,0,0,.6)"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              border-radius-md
              select-style
              mt-2
              mb-0
            "
            outlined
            single-line
            height="36"
          >
          </v-select>
        </v-col>
      </v-row> -->
        <v-btn
          block
          v-if="userInformations"
          :elevation="0"
          
          @click="
            $store.dispatch('updateUserInformations', userInformations),
              (profileChanged = false)
          "
          class="font-weight-bold text-xs btn-default bg-primary py-5 px-6 mt-6 mb-2 me-2 ms-auto"
          color="warning"
        >
        <span v-if="!updating">Änderungen speichern</span>
        <v-progress-circular
          v-else
          indeterminate
          color="white"
        ></v-progress-circular>
        </v-btn>
      </div>
    </v-card>
    <v-card class="card-shadow border-radius-l mt-6" id="basic">
      <div class="px-6 py-6">
        <h5 class="text-h5 font-weight-bold text-typo">Ansprechpartner/in</h5>
      </div>
      <div class="px-6 pb-6 pt-0">
        <v-row>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Vorname</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="Vorname"
              v-model="userInformations.firstname"
              class="font-size-input placeholder-lighter border border-radius-md text-light-input mt-2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Nachname</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="Nachname"
              v-model="userInformations.lastname"
              class="font-size-input placeholder-lighter border border-radius-md text-light-input mt-2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >E-Mail</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="Kontakt E-Mail-Adresse"
              v-model="userInformations.contactEmail"
              class="font-size-input placeholder-lighter border border-radius-md text-light-input mt-2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Telefon</label
            >
            <v-text-field
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder="Kontakt Telefonnummer"
              v-model="userInformations.contactPhone"
              class="font-size-input placeholder-lighter border border-radius-md text-light-input mt-2"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-btn
          block
          v-if="userInformations"
          :elevation="0"
         
          @click="
            $store.dispatch('updateUserInformations', userInformations),
              (profileChanged = false)
          "
          class="font-weight-bold text-xs btn-default bg-primary py-5 px-6 mt-6 mb-2 me-2 ms-auto"
          color="warning"
        >
        <span v-if="!updating">Änderungen speichern</span>
        <v-progress-circular
          class="pa-2"
          v-else
          indeterminate
          color="white"
        ></v-progress-circular>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "basic-info",
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
    updating(){
      return this.$store.getters.updating;
    }
  },
  watch: {
    userInformations: {
      handler: function (newValue) {
        console.log("Person with ID:" + this.userInformations.id + " modified");
        console.log("New firstname: " + this.userInformations.firstname);
        this.profileChanged = true;
        this.$store.commit("popLogin", false);
        this.$store.commit("popRegister", false);
        if (!this.userInformations) {
          this.$router.push("/").catch(() => {});
        }
      },
      deep: true,
    },
  },
  methods: {
    checkLoginState() {
      if (this.userInformations) {
        if (this.userInformations.isVerified) {
          return true;
        } else {
          this.$store.commit("accountCreated", true);
          this.$store.commit("popRegister", true);
          return false;
        }
      } else {
        this.$store.commit("popLogin", true);
        return false;
      }
    },
  },
  data() {
    return {
      gender: ["Männlich", "Weiblich", "Divers"],
      profileChanged: false,
      months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktomber",
        "November",
        "Dezember",
      ],
      days: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      years: ["2021", "2020", "2019"],
      languages: ["Englisch", "Französisch", "Spanisch", "Deutsch"],
    };
  },
};
</script>
