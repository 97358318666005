<template>
  <v-container fluid class="py-6" v-if="userInformations">
    <v-card class="bg-transparent">
        <v-row class="pa-0 ma-0">
          <h2 class="text-h3 mr-4 text-black font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/profile').catch(() => {})">Profil</h2>
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/settings').catch(() => {})">Einstellungen</h2>
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/subscription').catch(() => {})">Abonnement</h2>
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/team').catch(() => {})">Team</h2>
          <h2 class="text-h3 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/invoices').catch(() => {})">Rechnungen</h2>
        </v-row>
       
      </v-card>
    <v-row>
     
      <v-col >
        <!-- <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-l">
          <v-row>
            <v-col cols="auto">
              <v-avatar width="74" height="74" class="shadow border-radius-lg" v-if="userInformations.username || userInformations.avatar">
                <img
                  v-if="userInformations.avatar"
                  src="@/assets/img/jonas.png"
                  alt="Avatar"
                  class="border-radius-lg"
                />
                <p class="text-h1 my-auto text-primary">{{ userInformations.username[0] }}</p>
              </v-avatar>
            </v-col>
            <v-col cols="auto" class="my-auto">
              <div class="h-100">
                <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                  {{ userInformations.firstname }} {{ userInformations.lastname }}
                </h5>
                <p class="mb-0 font-weight-bold text-body text-sm">
                  {{ userInformations.username }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card> -->
        <v-row>
          <v-col cols="12" md="8">
            <basic-info></basic-info>
          </v-col>
          <v-col cols="12" md="4">
            <company-images/>
          </v-col>
        </v-row>
       
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BasicInfo from "./Widgets/BasicInfo.vue";
import ChangePassword from "./Widgets/ChangePassword.vue";
import DeleteAccount from "./Widgets/DeleteAccount.vue";
import Logout from "./Widgets/Logout.vue";
import CompanyImages from "./Widgets/CompanyImages.vue";
export default {
  name: "Settings",
  components: {
    BasicInfo,
    ChangePassword,
    DeleteAccount,
    Logout,
    CompanyImages
  },
  mounted(){
    // if(!this.userInformations){
    //   this.$router.push('/').catch(() => {})
    // }
  },
  computed: {
    userInformations(){
      return this.$store.getters.userInformations;
    }
  },
  data() {
    return {
      switche: true,
    };
  },
};
</script>
