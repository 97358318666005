<template>
  <v-container fluid class="py-6" v-if="userInformations">
    <v-card class="bg-transparent">
        <v-row class="pa-0 ma-0">
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/profile').catch(() => {})">Profil</h2>
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/settings').catch(() => {})">Einstellungen</h2>
          <h2 class="text-h3 mr-4 text-black font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/subscription').catch(() => {})">Abonnement</h2>
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/team').catch(() => {})">Team</h2>
          <h2 class="text-h3 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/invoices').catch(() => {})">Rechnungen</h2>
        </v-row>
       
      </v-card>
    <v-row>
     
      <v-col >
        <v-row>
          <v-col cols="12" md="8">
            <features/>
          </v-col>
          <v-col cols="12" md="4">
            <overview/>
          </v-col>
        </v-row>
        
       
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Features from "./Widgets/Features.vue";
import Overview from "./Widgets/Overview.vue";

export default {
  name: "Settings",
  components: {
    Overview,
    Features
  },
  mounted(){
    // if(!this.userInformations){
    //   this.$router.push('/').catch(() => {})
    // }
  },
  computed: {
    userInformations(){
      return this.$store.getters.userInformations;
    }
  },
  data() {
    return {
      switche: true,
    };
  },
};
</script>
