<template>
  <v-card class="card-shadow border-radius-l mt-6" id="notifications">
    <div class="px-6 pt-6">
      <h5 class="text-h5 font-weight-bold text-typo">Benachrichtigungen</h5>
      <p class="text-sm text-body">
        Wählen Sie aus, wie Sie Benachrichtigungen erhalten möchten.
      </p>
    </div>
    <div class="px-6 pb-6 pt-0">
      <v-simple-table class="table border-radius-l">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p
                  class="
                    mb-0
                    text-body text-capitalize text-md
                    font-weight-normal
                  "
                >
                Aktivität
                </p>
              </th>
              <th class="text-center">
                <p
                  class="
                    mb-0
                    text-body text-capitalize text-md
                    font-weight-normal
                  "
                >
                  Email
                </p>
              </th>
              <th class="text-center">
                <p
                  class="
                    mb-0
                    text-body text-capitalize text-md
                    font-weight-normal
                  "
                >
                  Push
                </p>
              </th>
              
            </tr>
          </thead>
          <tbody>
            
            <tr >
              <td
                class="text-sm text-secondary mb-0 py-3 ps-1 border-bottom"
                colspan="4"
              >
                <span class="text-dark d-block text-sm" >Meldungen</span>
                <span
                  class="text-xs font-weight-normal"
                  
                  >Benachrichtigen, wenn ein Nutzer bedenkliche Inhalte meldet.</span
                >
                
              </td>
              <td>
                <v-switch
                  :disabled="updating"
                  :ripple="false"
                  class="mt-0 pt-0 ms-8 switch"
                  v-model="userInformations.notifyEmailReport"
                  @change="updateInformations()"
                  hide-details
                  color="#3a416ff2"
                  inset
                ></v-switch>
              </td>
              <td>
                <v-switch
                :disabled="updating"
                  :ripple="false"
                  class="mt-0 pt-0 ms-5 switch"
                  v-model="userInformations.notifyPushReport"
                  @change="updateInformations()"
                  hide-details
                  color="#3a416ff2"
                  inset
                ></v-switch>
              </td>
              
            </tr>
            <tr >
              <td
                class="text-sm text-secondary mb-0 py-3 ps-1 border-bottom"
                colspan="4"
              >
                <span class="text-dark d-block text-sm" >Sicherheit</span>
                <span
                  class="text-xs font-weight-normal"
                  
                  >Anmeldung eines neuen Gerätes.</span
                >
                
              </td>
              <td>
                <v-switch
                :disabled="updating"
                  :ripple="false"
                  class="mt-0 pt-0 ms-8 switch"
                  v-model="userInformations.notifyEmailSignin"
                  hide-details
                  @change="updateInformations()"
                  color="#3a416ff2"
                  inset
                ></v-switch>
              </td>
              <td>
                <v-switch
                :disabled="updating"
                  :ripple="false"
                  class="mt-0 pt-0 ms-5 switch"
                  v-model="userInformations.notifyPushSignin"
                  hide-details
                  @change="updateInformations()"
                  color="#3a416ff2"
                  inset
                ></v-switch>
              </td>
              
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "notifications",
  data() {
    return {
      notifications: [
        {
          title: "Meldungen",
          description: "Benachrichtigen, wenn ein Nutzer bedenkliche Inhalte meldet.",
          switch1: true,
          switch2: false,
        },
        
        {
          title: "Sicherheit",
          text: "Anmeldung eines neuen Gerätes",
          switch1: true,
          switch2: true,
        },
      ],
    };
  },
 
  computed: {
    updating(){
      return this.$store.getters.updating;
    },
    userInformations(){
      return this.$store.getters.userInformations;
    }
  },
  methods:{
    updateInformations(){
      this.$store.dispatch('updateUserInformations', this.userInformations)
    }
  }
};
</script>
