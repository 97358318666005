<template>
  <v-card class="card-shadow border-radius-l mt-6" id="delete">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">Account löschen</h5>
      <p class="text-sm text-body">
        Sobald Sie Ihr Konto gelöscht haben, gibt es kein Zurück mehr. Seien Sie bitte sicher.
      </p>
    </div>
    <div class="px-6 pb-6 pt-0">
      <div class="d-sm-flex align-center ms-2">
        <v-switch
          :ripple="false"
          class="mt-0 pt-0 switch"
          v-model="switche"
          hide-details
          color="#3a416ff2"
          inset
        ></v-switch>
        <div class="ms-sm-3 mb-sm-0 mb-3">
          <span class="text-dark font-weight-bold d-block text-sm"
            >Bestätigen</span
          >
          <span class="text-xs text-body d-block"
            >Ich möchte mein Konto löschen.</span
          >
        </div>
        <!-- <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="font-weight-bolder btn-outline-secondary py-5 px-6 ms-auto"
          small
        >
          Deaktivieren
        </v-btn> -->
        <v-btn
          :ripple="false"
          elevation="0"
          :disabled="!switche"
          color="#fff"
          class="
            font-weight-bolder
            btn-danger
            
            py-5
            px-6
            ms-auto
          "
          @click="deleteAccount"
          :class="!switche ? 'font-weight-bolder' : 'btn-danger bg-gradient-danger'"
          small
        >
          Account löschen
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>

import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  name: "delete-account",
  data() {
    return {
      switche: false,
    };
  },
  methods: {
    deleteAccount() {
      this.$swal({
        title: "Sind Sie absolut sicher?",
        text: "Die Löschung kann nicht rückgängig gemacht werden!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Ja, löschen!",
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteAccount");
          this.$swal.fire("Gelöscht!", "Ihr Account wurde gelöscht.", "success").then((val) => {
            // this.$store.dispatch("logoutModal");
            // this.$router.push("/").catch(() => {});
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            "Abgebrochen!",
            "Ihr Account wurde nicht gelöscht",
            "error"
          );
        }
      });
    },
  }
};
</script>
