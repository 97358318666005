<template>
  <v-card class="card-shadow border-radius-l mt-6" id="change">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">Passwort ändern</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <v-row>
        <!-- <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >Aktuelles Passwort</label
          >
          <v-text-field
            hide-details
            outlined
            color="rgba(0,0,0,.6)"
            light
            placeholder="Aktuelles Passwort"
            class="
              font-size-input
              placeholder-lighter
              border border-radius-md
              mt-2
            "
          >
          </v-text-field>
        </v-col> -->
        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >Neues Passwort</label
          >
          <v-text-field
            v-model="newPassword"
            hide-details
            outlined
            color="rgba(0,0,0,.6)"
            light
            type="password"
            placeholder="Neues Passwort"
            class="
              font-size-input
              placeholder-lighter
              border border-radius-md
              mt-2
            "
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >Neues Passwort wiederholen</label
          >
          <v-text-field
            hide-details
            v-model="newPasswordRepeat"
            outlined
            color="rgba(0,0,0,.6)"
            light
            type="password"
            placeholder="Neues Passwort wiederholen"
            class="
              font-size-input
              placeholder-lighter
              border border-radius-md
              mt-2
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <h5 class="mt-10 mb-2 text-h5 font-weight-bold text-typo">
        Passwort Anforderungen
      </h5>
      <p class="text-muted mb-2">
        Bitte folgen Sie dieser Anleitung für ein starkes Passwort
      </p>
      <div class="d-sm-flex">
        <ul class="text-muted ps-6 mb-0">
          <li>
            <v-icon
              class="mr-2"
              :class="validateSymbol ? 'text-success' : 'text-danger'"
              size="12"
              >{{ validateSymbol ? "fa fa-check" : "fa fa-times" }}</v-icon
            >
            <span class="text-sm">Ein Sonderzeichen </span>
          </li>
          <li>
            <v-icon
              class="mr-2"
              :class="validateLength ? 'text-success' : 'text-danger'"
              size="12"
              >{{ validateLength ? "fa fa-check" : "fa fa-times" }}</v-icon
            >
            <span class="text-sm">Mindestens 8 Zeichen</span>
          </li>
          <li>
            <v-icon
              class="mr-2"
              :class="validateNumber ? 'text-success' : 'text-danger'"
              size="12"
              >{{ validateNumber ? "fa fa-check" : "fa fa-times" }}</v-icon
            >
            <span class="text-sm">Eine Nummer (2 werden empfohlen)</span>
          </li>
          <li>
            <v-icon
              class="mr-2"
              :class="validateEquality ? 'text-success' : 'text-danger'"
              size="12"
              >{{ validateEquality ? "fa fa-check" : "fa fa-times" }}</v-icon
            >
            <span class="text-sm">Passwörter müssen übereinstimmen</span>
          </li>
        </ul>
        <v-btn
          :ripple="false"
          elevation="0"
          :disabled="
            !validateSymbol ||
            !validateEquality ||
            !validateLength ||
            !validateNumber
          "
          color="#fff"
          class="font-weight-bolder btn-danger py-5 px-6 ms-auto"
          :class="
            !validateSymbol ||
            !validateEquality ||
            !validateLength ||
            !validateNumber
              ? 'font-weight-bolder '
              : 'btn-danger bg-gradient-success'
          "
          small
          @click="updatePassword"
        >
          Passwort ändern
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>

import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  name: "change-password",
  data() {
    return {
      newPassword: "",
      newPasswordRepeat: "",
    };
  },
  methods: {
    updatePassword() {
      if (
        !this.validateSymbol ||
        !this.validateEquality ||
        !this.validateLength ||
        !this.validateNumber
      )
        return;
      this.$store.dispatch("updateUserInformations", {
        password: this.newPassword,
      }).then((val) => {
        this.$swal.fire("Erfolg!", "Ihr Passwort wurde geändert", "success")
      });
    },
  },
  computed: {
    validateSymbol() {
      let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      if (format.test(this.newPassword)) {
        return true;
      } else {
        return false;
      }
    },
    validateLength() {
      return this.newPassword.length >= 8;
    },
    validateNumber() {
      return /\d/.test(this.newPassword);
    },
    validateEquality() {
      return this.newPassword && this.newPassword == this.newPasswordRepeat;
    },
  },
};
</script>
