<template>
  <v-card class="card-shadow border-radius-l mt-6" id="2fa">
    <div class="px-6 pt-6 d-flex">
      <div class="">
        <h5 class="text-h5 font-weight-bold text-typo">
          Zwei-Faktor-Authentifizierung
        </h5>
        <p class="text-sm text-body" v-if="userInformations.twoFactorEnabled">
          Bei unbekannten Geräten, wird ein Bestätigungscode gefordert.
        </p>
        <p class="text-sm text-body" v-if="userInformations.twoFactorEnabled">
          Ist keine Authentifizierungsmethode eingerichtet, wird ein Code an
          Ihre Account-E-Mail-Adresse gesendet.
        </p>
      </div>
      <v-btn
        elevation="0"
        small
        :disabled="updating"
        :ripple="false"
        height="21"
        class="border-radius-md font-weight-bolder px-3 py-3 badge-font-size ms-auto"
        @click="changeTwoFactor()"
        :class="
          userInformations.twoFactorEnabled
            ? 'text-success'
            : 'bg-light text-danger'
        "
        color="#cdf59b"
        >{{ userInformations.twoFactorEnabled ? "Aktiv" : "Inaktiv" }}</v-btn
      >
    </div>
    <div class="px-6 py-6">
      <div class="d-flex">
        <p class="my-auto text-body">Sicherheits PIN</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          {{ userInformations.safetyPin }}
        </p>
        <v-dialog v-model="pin" max-width="450px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="!userInformations.twoFactorEnabled"
              elevation="0"
              color="#fff"
              class="font-weight-bolder py-4 px-8"
              :class="
                userInformations.twoFactorEnabled ? 'btn-outline-default' : ''
              "
              small
              >{{ userInformations.safetyPin ? "Ändern" : "Hinzufügen" }}</v-btn
            >
          </template>
          <v-card class="card-shadow border-radius-l">
            <v-toolbar elevation="0" class="px-2">
              <div>
                <span class="font-weight-bold text-h5 text-black mb-0"
                  >Wiederherstellungs E-Mail</span
                >
              </div>
              <v-spacer></v-spacer>
              <v-btn icon @click="pin = false">
                <v-icon class="text-black">fas fa-times</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="pb-0">
              <v-container class="px-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="userInformations.safetyPin"
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Sicherheits PIN"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="card-padding">
              <v-btn
                block
                @click="save"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        
      </div>
      <hr class="horizontal dark my-4" />
      <div class="d-flex">
        <p class="my-auto text-body">Wiederherstellungs E-Mail</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          {{ userInformations.recoveryEmail }}
        </p>
        <v-dialog v-model="email" max-width="450px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="!userInformations.twoFactorEnabled"
              elevation="0"
              color="#fff"
              class="font-weight-bolder py-4 px-8"
              :class="
                userInformations.twoFactorEnabled ? 'btn-outline-default' : ''
              "
              small
              >{{
                userInformations.recoveryEmail ? "Ändern" : "Hinzufügen"
              }}</v-btn
            >
          </template>
          <v-card class="card-shadow border-radius-l">
            <v-toolbar elevation="0" class="px-2">
              <div>
                <span class="font-weight-bold text-h5 text-black mb-0"
                  >Wiederherstellungs E-Mail</span
                >
              </div>
              <v-spacer></v-spacer>
              <v-btn icon @click="email = false">
                <v-icon class="text-black">fas fa-times</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="pb-0">
              <v-container class="px-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="userInformations.recoveryEmail"
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Wiederherstellungs E-Mail"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="card-padding">
              <v-btn
                block
                @click="save"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "two-factor",
  computed: {
    updating() {
      return this.$store.getters.updating;
    },
    userInformations() {
      return this.$store.getters.userInformations;
    },
  },
  data() {
    return {
      pin: false,
      email: false,
    };
  },
  methods: {
    changeTwoFactor() {
      this.userInformations.twoFactorEnabled =
        !this.userInformations.twoFactorEnabled;
      this.$store.dispatch("updateUserInformations", this.userInformations);
    },
    save() {
      this.$store.dispatch("updateUserInformations", this.userInformations);
      this.email = false;
      this.pin = false;
    },
  },
};
</script>
