<template>
  <div>
    <v-card class="card-shadow border-radius-l mt-6" id="basic">
      <div class="px-6 py-6">
        <v-row class="ma-0 pa-0">
          <h5 class="text-h5 font-weight-bold text-typo">Titelbild</h5>
        </v-row>
      </div>
      <div class="px-6 pb-6 pt-0">
        <dropzone v-model="background"></dropzone>
      </div>
    </v-card>
    <v-card class="card-shadow border-radius-l mt-6" id="basic">
      <div class="px-6 py-6">
        <v-row class="ma-0 pa-0">
          <h5 class="text-h5 font-weight-bold text-typo">Logo</h5>
        </v-row>
      </div>
      <div class="px-6 pb-6 pt-0">
        <dropzone v-model="avatar"></dropzone>
      </div>
    </v-card>
  </div>
</template>
<script>
import Dropzone from "./Dropzone.vue";

export default {
  name: "images",
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
    updating(){
      return this.$store.getters.updating;
    }
  },
  components: {
    Dropzone,
  },
  methods: {
    checkLoginState() {
      if (this.userInformations) {
        if (this.userInformations.isVerified) {
          return true;
        } else {
          this.$store.commit("accountCreated", true);
          this.$store.commit("popRegister", true);
          return false;
        }
      } else {
        this.$store.commit("popLogin", true);
        return false;
      }
    },
  },
  data() {
    return {
      background: [],
      logo: [],
    };
  },
};
</script>
