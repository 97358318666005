<template>
  <v-container fluid class="py-6" v-if="userInformations">
    <v-card class="bg-transparent">
      <v-row class="pa-0 ma-0">
        <h2
          class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer"
          @click="$router.push('/profile').catch(() => {})"
        >
          Profil
        </h2>
        <h2
          class="text-h3 mr-4 text-black font-weight-bolder mb-0 cursor-pointer"
          @click="$router.push('/settings').catch(() => {})"
        >
          Einstellungen
        </h2>
        <h2
          class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer"
          @click="$router.push('/subscription').catch(() => {})"
        >
          Abonnement
        </h2>
        <h2
          class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer"
          @click="$router.push('/team').catch(() => {})"
        >
          Team
        </h2>
        <h2
          class="text-h3 text-light font-weight-bolder mb-0 cursor-pointer"
          @click="$router.push('/invoices').catch(() => {})"
        >
          Rechnungen
        </h2>
      </v-row>
    </v-card>
    <two-factor/>
    <notifications/>
    <sessions></sessions>
    <change-password></change-password>
    <delete-account></delete-account>
    <logout />
  </v-container>
</template>
<script>
import ChangePassword from "./Widgets/ChangePassword.vue";
import DeleteAccount from "./Widgets/DeleteAccount.vue";
import Logout from "./Widgets/Logout.vue";
import TwoFactor from "./Widgets/TwoFactor.vue";
import Notifications from "./Widgets/Notifications.vue";
import Sessions from "./Widgets/Sessions.vue";
export default {
  name: "settings",
  components: {
    ChangePassword,
    DeleteAccount,
    Logout,
    TwoFactor,
    Notifications,
    Sessions
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
  },
  data() {
    return {};
  },
};
</script>
