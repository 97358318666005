<template>
  <v-container fluid class="py-6" v-if="userInformations">
    <v-card class="bg-transparent">
        <v-row class="pa-0 ma-0">
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/profile').catch(() => {})">Profil</h2>
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/settings').catch(() => {})">Einstellungen</h2>
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/subscription').catch(() => {})">Abonnement</h2>
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/team').catch(() => {})">Team</h2>
          <h2 class="text-h3 text-black font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/invoices').catch(() => {})">Rechnungen</h2>
        </v-row>
       
      </v-card>
    <v-row>
     
      <v-col >
        <datatable-search/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatatableSearch from "./Widgets/DatatableSearch.vue";
export default {
  name: "team",
  components: {
    DatatableSearch,
    
  },
  computed: {
    userInformations(){
      return this.$store.getters.userInformations;
    }
  },
  data() {
    return {
     
    };
  },
};
</script>
