<template>
  <v-card class="card-shadow border-radius-l mt-6" id="delete">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">Ausloggen</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <div class="">
        
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-primary
            btn-primary bg-gradient-primary
            py-5
            px-6
            ms-auto
          "
          @click="logout"
         
          small
        >
          Ausloggen
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>

import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  name: "delete-account",
  data() {
    return {
      switche: false,
    };
  },
  methods: {
    logout() {
      this.$swal({
        title: "Abmelden",
        text: "Wollen Sie sich ausloggen?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Ja, ausloggen!",
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$swal.fire("Ausgeloggt!", "Sie wurden erfolgreich ausgeloggt.", "success").then((val) => {
            this.$store.dispatch("logoutModal");
            this.$router.push("/").catch(() => {});
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          // this.$swal.fire(
          //   "Abgebrochen!",
          //   "Ihr Account wurde nicht gelöscht",
          //   "error"
          // );
        }
      });
    },
  }
};
</script>
