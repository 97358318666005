<template>
  <v-container fluid>
    
    <v-card class="pa-5 my-3" style="background-color: rgba(200, 211, 209, 1);">
      <p class="text-h4">DEFINITION</p>
     <span class="text-body"> {{ glossary.explanation }}</span>
    </v-card> 
    <v-card class="pa-5 my-3" style="background-color: rgba(200, 211, 209, .4);">
      <p class="text-h4">ERLÄUTERUNG</p>
      <span class="text-body"> {{ glossary.explanationDetail }} </span>
    </v-card> 
    <v-card class="pa-5 my-3" style="background-color: rgba(200, 211, 209, .2);">
      <p class="text-h4">BEISPIEL</p> 
      <span class="text-body"> {{ glossary.example }} </span>
    </v-card>  
  </v-container>
</template>
<script>
export default {
  name: "Begriff",
  mounted() {
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getCategory");
  },
  watch: {
    glossary() {
      this.$store.commit("selectedGlossary", this.glossary);
      
      if((!this.checkGlossary && this.glossary )||( this.glossary && (this.checkGlossary.id != this.glossary.id))){
        this.$store.dispatch("postGlossaryView", this.glossary);
      }
      this.checkGlossary = this.glossary
    }
  },
  computed: {
    glossary() {
      if (this.$store.getters.glossary.data) {
        return this.$store.getters.glossary.data.find(element => element.expression == this.$route.params.id);
      } else {
        return [];
      }
    },
    categories() {
      return this.$store.getters.categories.data;
    },
  },
  data() {
    return {
      checkGlossary: null
    };
  },
};
</script>
