<template>
  <div class="mt-6">
    <datatable-search></datatable-search>
    
  </div>
</template>
<script>
import DatatableSearch from "./DatatableSearch.vue";
export default {
  name: "members",
  components: {
    DatatableSearch,
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
    updating(){
      return this.$store.getters.updating;
    }
  },
  methods: {
    checkLoginState() {
      if (this.userInformations) {
        if (this.userInformations.isVerified) {
          return true;
        } else {
          this.$store.commit("accountCreated", true);
          this.$store.commit("popRegister", true);
          return false;
        }
      } else {
        this.$store.commit("popLogin", true);
        return false;
      }
    },
  },
  data() {
    return {
      
    };
  },
};
</script>
