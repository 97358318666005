<template>
  <div>
    <v-card class="card-shadow border-radius-l mt-6" id="basic">
      <div class="px-6 py-6">
        <h5 class="text-h5 font-weight-bold text-typo">Übersicht</h5>
      </div>
      <div class="px-6 pb-6 pt-0">
        
      </div>
    </v-card>
   
  </div>
</template>
<script>
export default {
  name: "overview",
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
    updating(){
      return this.$store.getters.updating;
    }
  },

  methods: {
    checkLoginState() {
      if (this.userInformations) {
        if (this.userInformations.isVerified) {
          return true;
        } else {
          this.$store.commit("accountCreated", true);
          this.$store.commit("popRegister", true);
          return false;
        }
      } else {
        this.$store.commit("popLogin", true);
        return false;
      }
    },
  },
  data() {
    return {
     
    };
  },
};
</script>
